body {
  color: $black;
}

.page {
  height: 100vh;
  overflow: hidden;
  background-color: #f8fbff;

  &__container {
    display: flex;
  }

  &__content {
    flex-grow: 1;
    padding: 46px 60px 50px;

    @include desktop() {
      padding: 30px 20px 50px;
    }
  }

  &__content,
  .sidebar {
    height: calc(100vh - 85px);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

#root {
  font-family: $font;

  > .ant-spin {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

//TODO: replace to another file
.status-label {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  border-radius: 14px 0 14px 0;
  width: max-content;
  span {
    margin-top: 2px;
    font-weight: normal;
    font-size: 12px;
    line-height: 1;
    letter-spacing: -0.03em;
  }

  img + span {
    margin-left: 8px;
  }
  &.gray {
    background-color: #f8f7fa;
    color: $primaryGrey;
  }
  &.gray-green {
    background-color: #f8f8f8;
    color: $green;
  }
  &.gray-blue {
    background-color: #eef3f9;
    color: $primaryGrey;
    span {
      font-size: 14px;
    }
  }
  &.red {
    background-color: $secondaryRed;
    color: $white;
  }
  &.green {
    background-color: #edf7f3;
    color: $green;
    span {
      font-size: 14px;
    }
  }
  &.blue {
    background-color: #f4f9fe;
    color: $primaryBlue;
    span {
      font-size: 14px;
    }
  }
  &.red-opacity {
    border-radius: 20px;
    background-color: $opacityRed;

    span {
      color: $black;
    }
  }
  &.disabled {
    padding: 4px 18px;
    border-radius: 20px;
    background-color: $secondaryBlue;
    span {
      margin-left: 12px;
      color: $primaryGrey;
    }
  }
}

.box-shadow {
  background: $white;
  border: 1px solid #e1ecf6;
  box-shadow: 0 3px 9px rgba(104, 123, 116, 0.08);
  + .box-shadow {
    margin-top: 24px;
  }
}

.box-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .box-row-text {
    max-width: 68%;
  }

  .btn {
    padding: 0 20px;
  }
}

.box-shadow-title {
  font-weight: 500;
  font-size: 21px;
  line-height: 96%;
  letter-spacing: 0.01em;
  color: $black;
  margin-bottom: 24px;
}
.box-shadow-sub-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 150.5%;
  letter-spacing: -0.02em;
  color: $black;
  margin-bottom: 10px;
}

.box-shadow-padding {
  padding: 34px;
}

.info-box-list {
  > div + div {
    margin-top: 12px;
  }
}

.info-green-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;
  padding: 0 26px 0 24px;
  background-color: #f3faf8;
  border-left: 4px solid $green;

  .btn.primary {
    background-color: $green;
  }
}

.info-blue-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;
  padding: 0 26px 0 24px;
  background-color: #fbfdfe;
  border: 1px solid #e1ecf6;

  @include mobile() {
    position: relative;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 25px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  button {
    @include mobile() {
      margin: 0;
    }
  }
}

.upload-file__wrapper {
  height: 108px;
  background: $white;
  border: 2px dashed #a2a3b1;
  border-radius: 15px;

  .upload-file__label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 150.5%;
    letter-spacing: -0.02em;
    color: rgba(23, 24, 59, 0.5);
  }
}

input[type="file"] {
  display: none;
}

.lowercase {
  text-transform: lowercase;
}

.text-red {
  color: $primaryRed;
}

.text-greed {
  color: $green;
}

.ant-modal-root {
  font-family: $font;
}
