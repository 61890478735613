.custom-ant-table {
  .ant-table-thead > tr > th {
    text-align: center;
    color: $white;
    background-color: $primaryBlue;
  }

  .ant-table-tbody > tr {
    height: 54px;
  }
  .ant-table-tbody > tr > td {
    text-align: center;
    padding: 8px 24px;
  }
  .label-with-icon {
    img {
      cursor: pointer;
      margin-left: 6px;
    }
  }
}
