.icon {
  width: 20px;
  height: 20px;
  color: inherit;

  &.md {
    width: 16px;
    height: 16px;
  }
}
