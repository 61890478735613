.header {
  margin: 0 auto;

  .header__logo {
    width: auto;
    height: 40px;
  }

  .skew-dropdown {
    margin-left: 55px;
  }

  .dropdown__current {
    display: flex;
    min-width: 110px;
    padding: 10px 25px;
    transform: skew(40deg);

    &:hover {
      color: $black;
    }

    .dropdown__caret {
      margin-left: 20px;
    }
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 94px;
    margin: 0 60px;
    border-bottom: 1px solid $secondaryBlue;

    @include desktop() {
      margin: 0 20px;
    }

    @include tablet() {
      .header__col {
        &.center {
          display: none;
        }
      }
    }
  }

  &__col {
    display: flex;
    align-items: center;

    &.center {
      //position: absolute;
      top: 0;
      //right: 340px;
      //left: 340px;
      bottom: 0;
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
  }

  &-tab {
    position: relative;
    min-width: 180px;
    margin: 0 50px;
    padding: 0;
    border: none;
    background-color: transparent;
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
    color: $black;
    cursor: pointer;

    &:hover:not([disabled]) {
      font-weight: 500;
      color: $black;
    }

    &.active {
      font-weight: 500;

      &:before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        width: 16px;
        height: 14px;
        margin: auto 0;
        border-radius: 3px 0 3px 0;
        background-color: $green;
        transform: translate(-30px) skew(-50deg);
      }
    }

    &--energy {
      &.active {
        &:before {
          background-color: $primaryYellow;
        }
      }
    }
  }

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-left: 14px;
    border: 1px solid $border;
    background-color: transparent;
    cursor: pointer;
  }

  &-account {
    position: relative;
    margin-left: 14px;

    &-btn {
      display: flex;
      justify-content: space-between;
      min-width: 180px;
      border: none;
      padding: 8px 0;
      background-color: transparent;
      cursor: pointer;

      &__inner {
        padding: 0 12px;
        text-align: left;
      }

      &__user {
        font-size: 16px;
        line-height: 15px;
        color: $black;
        font-weight: 700;
      }

      &__position {
        margin-top: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: $primaryGrey;
      }

      &__box {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        border-left: 1px solid $border;
      }
    }

    &-list {
      position: absolute;
      z-index: 3;
      top: 56px;
      right: 0;
      width: 100%;
      min-width: 250px;
      background-color: $white;
      border: 1px solid $border;
      box-shadow: 0 8px 24px rgba(112, 144, 176, 0.15);

      &-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 18px 26px;
        border: none;
        background-color: transparent;
        color: $black;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: $primaryBlue;
        }

        + .header-account-list-item {
          border-top: 1px solid $border;
        }

        &__box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          margin-right: 14px;
        }

        &__icon {
          width: auto;
          height: 18px;
        }

        &__name {
          font-size: 18px;
          line-height: 18px;
          letter-spacing: 0.015em;
        }
      }
    }
  }

  .b2 .dropdown__list {
    min-width: 120px;
  }
}

.burger {
  margin-right: 20px;
  padding: 10px 20px;
  background-color: $primaryBlue;
  transform: skew(-40deg);
  border-radius: 10px 0;
  cursor: pointer;

  .burger-content {
    transform: skew(-8deg);

    div {
      height: 4px;
      width: 25px;
      margin-bottom: 5px;
      background-color: $white;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.opened {
    .burger-content {
      transform: skew(40deg) translateX(2px);

      div:first-child {
        transform: rotate(45deg);
        transform-origin: left;
      }

      div:last-child {
        transform: rotate(-45deg);
        transform-origin: left;
      }

      div:nth-child(2) {
        width: 0;
      }
    }
  }
}

.header-mobile-nav {
  position: fixed;
  top: 94px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  transform: translateX(-100vw);
  transition: all 250ms ease-in-out;
  z-index: 3;
  overflow-y: scroll;

  &.opened {
    transform: translateX(0);
  }

  .mobile-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 25px;

    @include mobile() {
      margin-top: 0;
    }
  }

  .skew-dropdown {
    width: max-content;
    margin-left: 38px;

    @include mobile() {
      margin-top: 25px;
    }

    .dropdown__current {
      @include mobile() {
        justify-content: center;
        font-size: 12px;
        padding: 10px;
      }
    }

    .dropdown__caret {
      @include mobile() {
        margin-left: 10px;
      }
    }
  }

  .sidebar {
    padding: 25px 0 96px;
  }

  .outside-click-wrapper {
    margin-right: 20px;

    @include mobile() {
      margin-top: 25px;
    }
  }

  .header-account-btn {
    @include mobile() {
      min-width: 110px;
    }
  }

  .header-account-btn__user {
    @include mobile() {
      font-size: 14px;
    }
  }

  .header-account-btn__position {
    @include mobile() {
      font-size: 12px;
    }
  }

  .header__col {
    margin-left: 10px;
    flex-direction: column;
    align-items: flex-start;

    .header-tab:first-child {
      margin-bottom: 20px;
    }
  }
}
