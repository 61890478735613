.order-gas-filter {
  padding: 36px 34px 0 34px;
  display: flex;
  flex-direction: column;

  .order-gas-filter-title {
    font-weight: 500;
    font-size: 21px;
    line-height: 96%;
    letter-spacing: 0.01em;
    color: $black;
    margin-bottom: 24px;
  }
  .order-gas-filter-sub-title {
    font-weight: normal;
    font-size: 16px;
    line-height: 150.5%;
    letter-spacing: -0.02em;
    color: $black;
    margin-bottom: 14px;
  }

  .form-selected-filter {
    display: flex;
    flex-wrap: wrap;
    .field {
      margin-top: 20px;
      margin-bottom: 0;

      @include mobile() {
        width: 47%;
        min-width: 47%;
        margin-right: 0;
      }
    }
    .underline-link {
      //margin-left: unset;
      margin-top: 20px;
    }
  }

  @include mobile() {
    padding: 25px 20px 20px;
  }
}

.order-gas-create {
  padding: 32px 34px;

  @include mobile() {
    padding: 25px 20px 20px;
  }
}

.order-gas-create-header {
  display: flex;
  align-items: center;
  margin-bottom: 34px;

  .order-gas-create-header-title {
    font-weight: 500;
    font-size: 21px;
    line-height: 96%;
    letter-spacing: 0.01em;
    color: $black;
  }

  .status-label {
    margin-left: 18px;
    margin-bottom: 3px;

    @include mobile() {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
  }

  @include mobile() {
    flex-wrap: wrap;
  }
}

.order-gas-create-total {
  display: flex;
  flex-direction: column;
  padding: 16px 18px;
  background-color: #fbfdfe;
  border: 1px solid #e1ecf6;
  margin-bottom: 44px;

  .order-gas-create-total-title {
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    color: #000000;
    span {
      font-weight: 500;
    }
    + .order-gas-create-total-title {
      margin-top: 12px;
    }
  }
}

.order-gas-calendar {
  padding: 36px;

  .order-gas-calendar-header {
    display: flex;
    align-items: center;
    margin-bottom: 28px;

    span {
      margin-left: 12px;
      font-weight: 500;
      font-size: 21px;
      line-height: 96%;
      letter-spacing: 0.01em;
      color: $black;

      @include mobile() {
        font-size: 18px;
      }
    }

    @include tablet() {
      padding: 0 0 0 20px;
    }
  }
  .order-gas-progress-bar {
    max-width: 400px;
    margin-bottom: 34px;

    @include tablet() {
      padding: 0 20px;
    }
  }
  .order-gas-calendar-footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 34px;
    margin-left: 20px;

    .underline-link {
      margin-top: 6px;
    }

    .btn {
      min-width: 280px;

      @include mobile() {
        min-width: 93%;
      }
    }

    @include tablet() {
      padding: 0 20px;
    }
  }
  .order-gas-calendar-body {
    max-width: 700px;
    width: 100%;
  }

  .error-message {
    position: absolute;
    bottom: 0;
    right: 0;
    color: $primaryRed;
  }

  @include tablet() {
    padding: 25px 0 20px;
  }
}

.order-gas-submit-wrap {
  display: flex;
  align-items: center;

  @include mobile() {
    width: 100%;
  }

  .error-message {
    margin-left: 40px;
    color: $primaryRed;
  }

  .btn.primary {
    @include mobile() {
      min-width: 80%;
      margin: 0 auto;
    }
  }
}
