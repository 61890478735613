.new-invoice-modal {
  &.new-invoice-modal-energiya {
    .new-invoice-modal__title {
      color: $green;
    }
  }

  .ant-modal-body {
    padding: 88px 112px 92px 112px;
  }

  .new-invoice-modal__wrapper {
    text-align: center;
  }

  .new-invoice-modal__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 102%;
    color: $primaryBlue;
  }

  .new-invoice-modal__text {
    margin-top: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 144%;
    color: #17183b;
  }

  .new-invoice-modal__buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .btn {
      min-width: 174px;

      + .btn {
        margin-left: 38px;
      }
    }
  }
}

.new-invoice-message {
  .new-invoice-message__title {
    color: $black;
    font-size: 24px;
    font-weight: 600;
    line-height: 102%;
  }

  .new-invoice-message__text {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150.5%;
    letter-spacing: -0.02em;
    color: $black;
  }
}

.new-invoice-energiya {
  .amount-to-pay .dashboard-download {
    background-color: #fff3d2;
  }
}

.invoice-wrap {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 34px;
  margin-top: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;

  @include tablet() {
    margin-top: 20px;
    padding: 20px;
  }
}
