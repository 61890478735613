.confirm-step {
  display: flex;
  flex-direction: column;
  align-items: center;

  .confirm-step-icon {
    margin-bottom: 38px;
  }

  .confirm-step-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 96%;
    letter-spacing: 0.01em;
    color: $primaryBlue;
  }

  .confirm-step-sub-title {
    width: 80%;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 144%;
    text-align: center;
    color: $black;
  }

  .confirm-step-sub-title-bold {
    font-weight: 500;
  }

  .confirm-step-list {
    display: flex;
    align-items: center;
    margin: 42px 0;
  }
  .confirm-step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: max-content;
    min-width: 132px;

    &.active {
      .confirm-step-item-round {
        background-color: $primaryBlue;
        &:after {
          background-color: $primaryBlue;
        }
      }
      .confirm-step-item-title {
        color: $black;
      }
    }

    &:last-child {
      .confirm-step-item-round:after {
        content: none;
      }
    }
  }

  .confirm-step-item-round {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    background-color: #a2a3b1;

    &:after {
      content: "";
      width: 80px;
      height: 1px;
      position: absolute;
      top: 12px;
      background-color: #a2a3b1;
      left: 38px;
    }

    span {
      margin-top: 2px;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.02em;
      color: $white;
    }
  }
  .confirm-step-item-title {
    margin-top: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    text-align: center;
    letter-spacing: -0.02em;
    color: rgba(23, 24, 59, 0.5);
  }

  .confirm-step-text {
    margin-top: 34px;
    font-size: 16px;
    line-height: 144%;
    text-align: center;
    color: rgba(23, 24, 59, 0.87);

    + .confirm-step-text {
      margin-top: 18px;
    }
  }

  .btn {
    margin-top: 42px;
    min-width: 434px;

    @include mobile() {
      min-width: 80%;
    }
  }
}

.confirm-step-modal {
  &.success {
    .ant-modal-body {
      padding: 70px 84px 90px 84px;
    }

    .confirm-step .confirm-step-title {
      color: $black;
    }
  }

  .ant-modal-body {
    padding: 70px 0 90px 0;
  }
}
