$primaryBlue: #00b5e5;
$secondaryBlue: #e1ecf6;
$primaryYellow: #fdd66a;
$opacityYellow: #fef2cf;
$primaryRed: #e87461;
$secondaryRed: #e88372;
$opacityRed: #f6cdc7;
$black: #000;
$primaryGrey: #979797;
$secondaryGrey: rgba(23, 24, 59, 0.5);
$activeGrey: #8b8b9c;
$green: #229c71;
$white: #fff;
$border: #eeeff0;
$text: #242424;

:root {
  --primaryBlueToGreen: #3581b8;
  --textBlackToWhite: #ffffff;
}

.energiya-page {
  --primaryBlueToGreen: #367961;
  --textBlackToWhite: #17183b;
}
