.calendar {
  display: block;
  position: relative;
  border: 1px solid #e1ecf6;
  height: auto;
  width: 100%;

  @include mobile() {
    width: 700px;
  }
}

.calendar-weeks {
  display: flex;
  height: 42px;
  background-color: $primaryBlue;

  .calendar-week-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
    font-weight: normal;
    font-size: 10px;
    line-height: 96%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: $white;

    &:not(:first-child) {
      border-left: 1px solid #eeeff0;
    }
  }
}
.calendar-day-row {
  display: flex;
  flex-direction: row;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid #e1ecf6;
  }

  .calendar-day {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3px 4px 4px 4px;
    width: calc(100% / 7);
    position: relative;
    height: 54px;
    overflow: hidden;
    background: $white;
    transition: 0.25s ease-out;
    color: $primaryGrey;
    font-weight: normal;
    font-size: 12px;
    line-height: 96%;
    letter-spacing: -0.02em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
    }

    &:not(:first-child) {
      border-left: 1px solid #e1ecf6;
    }

    &.disabled {
      background: #eef3f9;
      pointer-events: none;
      color: rgba(23, 24, 59, 0.5);
    }

    &:not(.active) {
      span {
        padding-top: 4px;
      }
    }

    &.active {
      span {
        color: $white;
        border-radius: 100%;
        width: 18px;
        height: 18px;
        background: $primaryBlue;
      }

      .add-to-order {
        background-color: $white;
      }
    }
    &:hover {
      .add-to-order {
        background-color: $white;
      }
    }
  }
}

.add-to-order {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 90px;
  font-weight: normal;
  font-size: 14px;
  line-height: 96%;
  letter-spacing: -0.03em;
  color: $primaryGrey;
  background: #f8f9fb;
  cursor: pointer;
}

.input-spinner-container {
  display: flex;
  width: 90px;
  height: 26px;
  background: #f6f9fd;
  border: 1px solid #f6f9fd;
  cursor: pointer;

  .input-spinner {
    display: flex;
    align-items: center;
    text-align: center;
    width: 40px;
    border: 0;
    outline: 0;
    background: #f6f9fd;
    font-weight: normal;
    font-size: 14px;
    line-height: 96%;
    letter-spacing: -0.02em;
    color: $black;
  }
  .input-spinner-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: $primaryBlue;
    background-color: $white;
    width: 24px;
    flex-grow: 0;
    img {
      width: 14px;
      height: 14px;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.scroll-wrap {
  @include tablet() {
    overflow-x: auto;
  }
}
