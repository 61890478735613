.analytics-b2c {
  border-color: #e1ecf6;

  .analytics-b2c-header {
    padding: 37px 24px 27px 34px;

    @include mobile() {
      padding: 25px 20px 15px;
    }
  }

  .analytics-b2c-history-chart {
    border-top: 1px solid #e1ecf6;
    padding: 37px 34px;

    @include mobile() {
      padding: 25px 20px 15px;
    }

    .analytics-b2c-history-chart__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 54px;

      .form-selected-filter {
        justify-content: flex-end;
        margin-bottom: 0;

        @include mobile() {
          justify-content: flex-start;
          margin-top: 20px;
        }
      }

      .analytics-b2c-history-chart__title {
        width: 100%;
        color: #17183b;
        font-size: 18px;
        line-height: 96%;
        font-weight: 500;

        @include mobile() {
          font-size: 16px;
        }
      }
    }
  }

  .analytics-b2c-table {
    margin: 0 34px;
    padding: 29px 0;
    border-top: 1px solid #e1ecf6;
    overflow-x: auto;

    @include mobile() {
      margin: 0 20px;
    }

    .analytics-b2c-btn {
      display: flex;
      align-items: center;
      width: 100%;
      border: none;
      background-color: transparent;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;

      @include mobile() {
        font-size: 16px;
      }

      &.active {
        img {
          transform: rotate(90deg);
        }
      }

      img {
        margin-right: 16px;
      }
    }

    .month {
      text-align: left;
      width: 112px;
      padding: 16px;
      text-transform: capitalize;
    }

    .tariff {
      text-align: left;
      width: 137px;
      padding: 16px;
    }

    .price {
      width: 79px;
    }

    .to-pay,
    .payed {
      width: 95px;
    }

    .recalculation {
      width: 111px;
    }

    .fine {
      width: 86px;
    }

    .balance {
      width: 101px;
    }

    .overpayment {
      width: 108px;
    }

    .positive-balance {
      color: $green;
    }

    .negative-balance {
      color: $primaryRed;
    }

    .custom-ant-table {
      padding: 30px 0 20px 0;
      overflow-x: auto;
      width: auto;
      min-width: auto;
    }

    .ant-table-wrapper {
      width: 100%;
      min-width: 932px;
    }

    .table-header-title {
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 144%;
      color: $white;
    }

    .table-header-sub-title {
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 96%;
      letter-spacing: -0.03em;
      color: rgba(255, 255, 255, 0.87);
    }
  }

  .form-selected-filter .field {
    margin-right: 0;
  }

  .analytics-b2c-table__even-payment {
    margin-top: 8px;
    font-size: 12px;
  }
}
