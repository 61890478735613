.dropdown {
  position: relative;

  &.active {
    .dropdown__caret {
      transform: rotate(180deg);
    }
  }

  &__current {
    padding: 6px 12px;
    background-color: transparent;
    font-size: 12px;
    color: $black;
    font-weight: 500;
    cursor: pointer;
  }

  &__caret {
    margin-left: 6px;
  }

  &__list {
    position: absolute;
    top: 38px;
    left: 0;
    min-width: 100%;
    background-color: $white;
    border: 1px solid $border;
    box-shadow: 0 8px 24px rgba(112, 144, 176, 0.15);
  }

  &__item {
    display: block;
    padding: 6px 12px;
    font-size: 14px;
    color: $black;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.ant-dropdown-menu-root.ant-dropdown-menu {
  padding: 0;
}

.ant-dropdown-open {
  .dropdown__caret {
    transform: rotate(180deg);
  }
}

.skew-dropdown {
  border: 1px solid $primaryBlue;
  border-radius: 10px 0 10px 0;
  transform: skew(-40deg);
  cursor: pointer;
}
