.classic-modal {
  .ant-modal-header {
    background-color: $primaryBlue;
    padding: 21px 24px;
  }

  .ant-modal-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 96%;
    letter-spacing: 0.01em;
    color: $white;
  }

  .team-form {
    margin: 0 auto;
    width: 100%;
    max-width: 434px;
    padding: 10px 0 20px 0;

    .field {
      width: 100%;

      .ant-select-selector {
        min-height: 48px;
        border: 1px solid rgba(23, 24, 59, 0.4);
        border-radius: 10px;
      }

      .ant-select-selection-item {
        font-weight: normal;
      }
    }

    .btn {
      width: 100%;
    }

    .ant-input,
    .field__input {
      border-radius: 10px;
    }

    label {
      margin-bottom: 7px;
      font-size: 10px;
    }
  }

  .team-remove {
    margin: 0 auto;
    width: 100%;
    max-width: 524px;
    padding: 60px 0;

    @include mobile() {
      padding: 40px 0;
    }

    .confirm-step {
      margin-bottom: 30px;
    }
  }

  .classic-modal-footer-btn {
    display: flex;
    margin-top: 20px;
    width: 100%;

    &.double {
      .btn {
        width: calc(50% - 12px);

        @include mobile() {
          width: calc(100% - 45px)
        }

        + .btn {
          margin-left: 24px;

          @include mobile() {
            margin-top: 20px;
            margin-left: 0;
          }
        }
      }

      @include mobile() {
        flex-direction: column;
        align-items: center;
      }
    }
    .btn {
      flex-shrink: 0;
      width: 100%;
    }
  }
}

.notification-modal-item {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 20px 14px;
  background-color: #FBFDFE;
  border: 1px solid #E1ECF6;

  .notification-item-time {
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #8B8B9D;
  }
  .notification-item-title {
    margin: 7px 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 112.5%;
    letter-spacing: -0.03em;
    color: $text;
  }
  .notification-item-sub-title {
    margin-bottom: 14px;
    font-weight: normal;
    font-size: 14px;
    line-height: 110.5%;
    letter-spacing: -0.02em;
    color: $text;
  }
  .notification-item-more {
    width: max-content;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #17183B;
    border-bottom: 1px solid #17183B;
  }
  .notification-item-footer {
    margin-top: 21px;
  }
  .btn {
    width: 100%;
    padding: 0 24px;
  }
}

.notification-drawer {
  &.ant-drawer-right {
    top: 73px;
    .ant-drawer-content-wrapper {
      height: calc(100% - 73px);
    }
  }
  .ant-drawer-mask {
    background: none;
  }

  .ant-drawer-header {
    height: 76px;
    padding: 30px 24px;
    background-color: $primaryBlue;
    .ant-drawer-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 96%;
      letter-spacing: -0.03em;
      color: $white;
      img {
        margin-right: 14px;
      }
    }
    .ant-drawer-close {
      padding: 28px 24px;
    }
  }
}

.success-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  text-align: center;

  .ant-modal-content {
    min-width: 400px;
  }

  &__wrapper {
    margin: 0 auto;

    .btn {
      margin-top: 32px;
    }
  }

  .success-modal__title {
    color: $black;
    font-weight: 500;
    font-size: 18px;
    line-height: 135.5%;
    letter-spacing: 0.015em;

    &--blue {
      color: $primaryBlue;
      margin: 20px auto;
    }

    &--green {
      color: $green;
      margin: 20px auto;
    }
  }

  &__body {
    max-width: 325px;
    margin: 0 auto;
  }

  &__buttons {
    display: flex;
    padding: 0 40px;

    @include mobile() {
      flex-direction: column;
      padding: 0;
    }

    .btn {
      margin: 15px 20px 20px;

      &:nth-child(1) {
        width: 160px;
        margin-right: 0;

        @include mobile() {
          width: 85%;
          margin: 10px 20px;
        }
      }

      @include mobile() {
        width: 85%;
        margin: 10px 20px;
      }
    }
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 160px;

    div + div {
      margin-top: 10px;
    }
  }

  .amount-to-pay {
    margin-top: 25px;
  }
}

div .ant-modal-content {
  border-radius: 30px;
}

.delete-account-modal {
  min-width: 650px;

  @include mobile() {
    min-width: 100%;
  }

  &__wrapper {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mobile() {
      padding: 20px 0;
    }
  }

  &__title {
    margin-bottom: 15px;
    color: $primaryBlue;
    font-size: 24px;
    line-height: 1;
    font-weight: 500;
  }

  &__address {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    text-align: center;
  }

  &__subtitle {
    width: 80%;
    margin-bottom: 30px;
    line-height: 17px;
    font-weight: 300;
    text-align: center;

    @include mobile() {
      width: 100%;
    }
  }

  &__buttons {
    @include mobile() {
      text-align: center;
    }
    .btn {
      width: 170px;

      @include mobile() {
        width: 250px;
      }

      &:first-child {
        margin-right: 40px;

        @include mobile() {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.backModal {
  padding: 30px 40px;
  text-align: center;

  @include mobile() {
    padding: 20px 0;
  }

  &__title {
    color: #00B5E5;
    font-size: 24px;
    font-weight: 500;
  }

  &__text {
    padding: 20px 40px;

    @include mobile() {
      padding: 20px 10px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-around;
    gap: 10px;

    @include mobile() {
      flex-direction: column;
      align-items: center;
      padding: 0 30px;
    }

    button {
      width: 46%;

      @include mobile() {
        width: 90%;
      }
    }
  }
}

.invoice-pay-modal {
  .amount-to-pay {
    justify-content: center;
    margin-top: 10px;

    .btn {
      @include tablet() {
        width: 100%;
      }
    }
  }

  .ant-form {
    padding: 10px;
    @include tablet() {
      padding-top: 0;
    }
  }

  .success-modal {
    &__body {
      max-width: 420px;
      margin: 15px auto;
      text-align: center;
    }

    &__title {
      color: $black;
      font-weight: 500;
      font-size: 18px;
      line-height: 135.5%;
      letter-spacing: 0.015em;

      &--blue {
        margin: 20px auto 0;
        color: $primaryBlue;
        text-align: center;
      }

      &--green {
        margin: 20px auto;
        color: $green;
        text-align: center;
      }
    }
  }
}

.deleteDocReasonModal {
  .backModal {
    &__text {
      padding: 20px 0 10px;
    }

    &__buttons {
      width: 100%;
    }
  }

  .field {
    width: 90%;

    .ant-form-item-label {
      label {
        margin-bottom: 6px;
        text-transform: none;
      }
    }

    .ant-input {
      border-radius: 10px;
    }
  }
}

.team-edit {
  .form-head {
    margin-bottom: 30px;
    margin-left: 20px;
  }

  .form-title {
    font-size: 24px;
    font-weight: 500;
  }

  .form-subtitle {
    font-size: 18px;
  }
}
