.form {
  .form__title {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: $black;
    font-weight: 500;
    font-size: 18px;
    line-height: 135.5%;
    letter-spacing: 0.015em;
    margin-bottom: 34px;

    &.block {
      display: block;
    }

    .email {
      color: $primaryBlue;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .field {
    display: flex;
    flex-direction: column;
  }
}

.profile .profile-tab-content {
  .form .ant-form {
    align-items: center;
  }

  .underline-link {
    margin-left: auto;
    margin-right: auto;
  }
}
