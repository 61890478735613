.order-energiya {
  .underline-link {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 150.5%;
    letter-spacing: -0.02em;
    color: $green;
    margin-bottom: 40px;
  }

  .upload-file__wrapper {
    margin-bottom: 40px;
  }

  .btn {
    width: 434px;
    background-color: $green;
  }

  .order-energiya-form {
    align-items: flex-start;
  }
}

.order-energiya-type-A1 {
  margin-bottom: 24px;
  .order-energiya-create-sub-title {
    margin-bottom: 8px;
  }
  .underline-link {
    margin: 0 0 24px 16px;
  }
}

.info-box-grid {
  justify-content: flex-start;
  margin: 6px 0 28px 0;

  > div {
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
      margin-left: 220px;
    }
  }
  .info-box-grid-title {
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    color: #000000;

    span {
      font-weight: 500;
    }
    + .info-box-grid-title {
      margin-top: 12px;
    }
  }
}

.order-energiya-create-sub-title {
  display: flex;
  font-weight: normal;
  font-size: 16px;
  line-height: 150.5%;
  letter-spacing: -0.02em;
  color: $black;
  margin-bottom: 10px;

  &.step4 {
    margin: 36px 0 28px 0;
  }
  &.step5 {
    margin: 36px 0 44px 0;
  }
  &.step7 {
    margin: 36px 0;
  }
}

.order-energiya-form {
  .info-box-grid {
    justify-content: space-between;
    grid-column: 1/4;
    height: 52px;
    .underline-link {
      color: $primaryGrey;
      margin-bottom: 0;
    }
  }
  .order-energiya-form-input {
    .field {
      width: 320px;
      margin-bottom: 40px;
    }
    .btn {
      width: 280px;
      margin-left: 20px;
    }
  }
  .form-grid-3cols {
    justify-content: flex-start;
    grid-auto-rows: auto;
    .field {
      width: 246px;
    }
    > .btn {
      margin-top: 34px;
      margin-left: 0;
    }
    .info-box-grid {
      margin-bottom: 6px;
    }
  }
  .order-energiya-form-radio {
    margin-bottom: 64px;
    margin-left: 8px;
    .radio {
      + .radio {
        margin-top: 24px;
      }
    }
  }

  .error-message {
    position: absolute;
    bottom: 50px;
    color: $primaryRed;
    margin-top: 10px;
  }
}

.order-energiya-form-wrap {
  width: 100%;

  .energiya-btn-submit {
    width: 280px;
    margin-left: 20px;
  }
}

.order-energiya-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}
