.b2b-bot-auth-page {
  .page {
    &__content {
      height: 100%;
      overflow-y: auto;
      padding: 40px 10px 25px;
    }
  }
}

.bot-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  .iit-widget {
    width: 600px;

    @include mobile() {
      width: 100%;
      min-height: 600px;
    }
  }

  & > div {
    @include mobile() {
      width: 100%;
    }
  }

  .registration-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 45px;
  }

  .reg-content-title-blue {
    max-width: 400px;
    margin-top: 25px;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
  }
}
